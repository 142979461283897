<template>
  <footer id="footer">
    <div class="container full h-100">
      <div class="row align-content-center h-100">
        <a class="col-lg-6 footer-brand text-center text-lg-left mb-2" href="/">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="51.000000pt"
        height="30.000000pt"
        viewBox="0 0 312.000000 121.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)"
          fill="#667084"
          stroke="none"
        >
          <path
            d="M1516 1006 c6 -16 -12 -56 -30 -68 -6 -4 -24 -8 -40 -8 -26 0 -28 -2
-22 -27 4 -16 20 -40 37 -55 91 -85 218 55 130 143 -31 31 -85 42 -75 15z"
          />
          <path
            d="M770 890 l-32 -120 -68 0 c-156 0 -287 -124 -321 -303 -20 -109 23
-185 119 -213 67 -20 208 -15 289 10 50 16 52 18 66 69 9 28 52 185 97 347 45
162 84 303 86 313 5 15 -4 17 -99 17 l-104 0 -33 -120z m-70 -240 c0 -14 -68
-258 -75 -270 -11 -18 -54 -11 -66 11 -23 44 21 202 69 246 25 23 72 32 72 13z"
          />
          <path
            d="M1165 770 c-106 -12 -147 -29 -157 -65 -5 -16 -35 -124 -66 -240
l-59 -210 100 -3 c55 -1 102 0 105 2 4 4 26 81 103 360 10 38 11 39 53 33 l42
-6 31 63 32 64 -37 6 c-42 7 -49 6 -147 -4z"
          />
          <path
            d="M2367 769 c-139 -22 -253 -144 -282 -302 -18 -93 21 -172 100 -208
35 -16 68 -19 248 -19 114 0 207 -2 207 -5 0 -3 -11 -27 -24 -54 l-25 -48 155
84 c84 47 154 89 154 93 0 5 -46 45 -102 89 -109 87 -118 88 -118 19 l0 -38
-188 0 c-170 0 -191 2 -205 18 -15 17 -23 42 -13 42 32 0 302 42 308 48 14 14
30 124 23 161 -18 97 -107 142 -238 120z m41 -121 c7 -7 12 -29 12 -50 0 -43
-11 -51 -89 -61 l-43 -5 5 35 c11 65 81 115 115 81z"
          />
          <path
            d="M1386 753 c-20 -71 -136 -493 -136 -497 0 -3 46 -6 103 -6 l103 0 14
53 c12 48 113 410 126 450 5 15 -4 17 -100 17 -91 0 -105 -2 -110 -17z"
          />
          <path
            d="M1640 647 c0 -68 3 -185 7 -260 l6 -137 103 0 c97 0 104 2 117 23 50
81 279 470 283 482 5 13 -9 15 -88 15 l-95 0 -46 -97 c-25 -54 -59 -127 -76
-163 -35 -76 -36 -71 -16 75 8 61 15 127 15 148 l0 37 -105 0 -105 0 0 -123z"
          />
        </g>
      </svg>
          <span class="ml-2">FİKİR PLATFORMU</span>
        </a>
        <ul class="footer-nav col-lg-6 text-center text-lg-right">
          <router-link tag="li" class="" to="/contactus">
            <a>İletişim</a>
          </router-link>
          <router-link tag="li" class="" to="/FrequentlyAskedQuestion">
            <a>Sıkça Sorulan Sorular</a>
          </router-link>
          <router-link tag="li" class="" to="/termofuse">
            <a>Kullanım Şartları ve Gizlilik</a>
          </router-link>
        </ul>

        <span class="col-12 text-center text-lg-left"
          >Copyright 2019 Doğuş Teknoloji</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style></style>
