<template>
  <button
    @click="topFunction"
    id="gotoTop"
    title="Go to top"
    :style="[scroll > 380 ? { display: 'block' } : '']"
  ></button>
</template>

<script>
export default {
  name: "GotoTop",
  data() {
    return {
      scroll: "",
    };
  },
  methods: {
    topFunction() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollControll(e) {
      this.scroll = e.target.scrollingElement.scrollTop;
    },
  },
  beforeMount() {
    document.addEventListener("scroll", this.scrollControll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollControll);
  },
};
</script>

<style>
</style>