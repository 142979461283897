<template>
  <div>
    <MainHeader />
    <slot></slot>
    <GotoTop />
    <MainFooter />
    <transition name="fade">
      <Loading v-if="isLoading" @close="isLoading = $event" />
    </transition>
  </div>
</template>

<script>
import MainHeader from "../components/Main/MainHeader.vue";
import MainFooter from "../components/Main/MainFooter.vue";
import GotoTop from "../components/Main/GotoTop.vue";
import Loading from "../components/Modals/Loading.vue";
export default {
  name: "MainLayout",
  components: {
    MainHeader,
    MainFooter,
    GotoTop,
    Loading,
  },
  computed: {
    isLoading() {
      return this.$store.getters["loading/getIsLoading"];
    },
  },
};
</script>

<style></style>
