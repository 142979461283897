<template>
  <div class="loading-container" style="overflow-y: auto; height: 100vh" @click.stop>
    <div
      class="loading-popup"
      tabindex="-1"
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style="display: flex"
    >
      <div class="loading-content">
        <img src="../../assets/images/ino_plate.gif" alt="loading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      styleOverflow: null,
    };
  },
  methods: {},
  created() {
    const $body = document.querySelector("body");
    this.styleOverflow = $body.style.overflow;
    $body.style.overflow = "hidden";
  },
  updated() {
    const $body = document.querySelector("body");
    $body.style.overflow = this.styleOverflow;
  },
};
</script>

<style>
@import "../../assets/styles/scss/loading.scss";
</style>
