<template>
  <svg
    width="84px"
    height="84px"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 324.78 288.28"
  >
    <title>Fikir Platformu</title>
    <path
      class="cls-11"
      d="M233.76,16.89a39.13,39.13,0,0,0-16,11.7C210.94,37.42,206,58.21,206,58.21L197.63,63a5.7,5.7,0,0,0-2.87,4.67L194,83.55a5,5,0,0,0,2.4,4.48l1.48.88,4-4.89a3,3,0,0,1,3.37-.9c1.41.54,3.31,1.28,5.21,2s3.89,1.18,5.34,1.61a3,3,0,0,1,2.12,2.78l.15,6.3,1.71.21a5,5,0,0,0,4.64-2.07l9.12-13a5.7,5.7,0,0,0,.59-5.46l-3.72-8.92s8.85-19.45,8.85-30.61A39.13,39.13,0,0,0,233.76,16.89Zm-10.94,32a7,7,0,0,1-4.35-8.86h0a7,7,0,1,1,13.2,4.51h0A7,7,0,0,1,222.82,48.93Z"
    ></path>
    <path
      class="cls-11"
      d="M243.4,130.06a4.42,4.42,0,1,1-2.75-5.61A4.42,4.42,0,0,1,243.4,130.06Z"
    ></path>
    <circle
      class="cls-11"
      cx="224.16"
      cy="130.44"
      r="1.77"
      transform="translate(-34.5 111.51) rotate(-26.01)"
    ></circle>
    <circle
      class="cls-11"
      cx="175.64"
      cy="95.28"
      r="8.59"
      transform="translate(-10.54 22.71) rotate(-7.18)"
    ></circle>
    <path
      class="cls-11"
      d="M156.28,108.49a2,2,0,1,1-1.23-2.49A2,2,0,0,1,156.28,108.49Z"
    ></path>
    <path
      class="cls-11"
      d="M72.76,120.69c-.29.59-.57,1.19-.82,1.81l.78-.25Z"
    ></path>
    <path
      class="cls-11"
      d="M275.18,170.12l-4.55-7.89,6.45-6.43a16.08,16.08,0,0,0,4.75-11.44c0-.31,0-.63,0-.94-.27,0-.55,0-.83,0A13.63,13.63,0,0,0,270.78,148a22.38,22.38,0,0,0-28.4,1,24.65,24.65,0,0,0-18.38-12c-8.79-.69-12.08,1.68-12.08,1.68a12.86,12.86,0,0,1,6.65-4.79,18.56,18.56,0,0,0-6.93-8.65,9.12,9.12,0,0,0-4.9-10.37l4.67-23.52-5.3-1.81-9.28,21.94a9.15,9.15,0,0,0-9.2,3.48,14.6,14.6,0,0,1,9.87,7.89c-6.53-7.44-18.37-7.32-24.19-1.75a24.7,24.7,0,0,0-22.85-5.32,24.27,24.27,0,0,0-40.58-6.31,26.66,26.66,0,0,0-37.12,11.28l0,1.56-.78.25-7.16,2.33a31.63,31.63,0,1,0,23.3,58.68l10-4.72,5.42,9.62c4.64,8.24,20.08,16.58,40.61,16.58a73.18,73.18,0,0,0,10.78-.8l10.4-1.55,2.79,10.14a34.26,34.26,0,0,0,64.27,5l3.91-8.65,9.3,1.92a27.72,27.72,0,0,0,33.31-27.11,27.88,27.88,0,0,0-3.68-13.78ZM74.56,159.24a5.59,5.59,0,1,1,5.59-5.59A5.58,5.58,0,0,1,74.56,159.24Zm55.95-13.85A11.79,11.79,0,1,1,142.3,133.6,11.79,11.79,0,0,1,130.51,145.39Zm49.88,21.39a5.7,5.7,0,1,1-3.55-7.23A5.7,5.7,0,0,1,180.39,166.78Zm10.87,16.8a2.36,2.36,0,1,1-1.47-3A2.35,2.35,0,0,1,191.26,183.58Zm24.94-27.4a7.86,7.86,0,1,1-4.9-10A7.86,7.86,0,0,1,216.2,156.18Zm26.38,31.43a5.56,5.56,0,1,1,5.56-5.56A5.56,5.56,0,0,1,242.58,187.61Z"
    ></path>
    <path
      class="cls-22"
      d="M302.59,144.36a36.87,36.87,0,0,0-18.48-32c0-.49,0-1,0-1.47a50,50,0,0,0-39.4-48.79c-.83,3-1.77,5.88-2.71,8.52a41,41,0,0,1,32.63,47.18,28,28,0,0,1,10.81,46.37,39.54,39.54,0,0,1-42.3,58.42,46.13,46.13,0,0,1-86.53-6.7,84.89,84.89,0,0,1-12.53.93c-23.52,0-43.52-9.44-50.94-22.61A43.5,43.5,0,1,1,61.1,113.55,45,45,0,0,1,106,69.9a44.37,44.37,0,0,1,7,.54,38.71,38.71,0,0,1,57.1-9.84,39.44,39.44,0,0,1,24.5-8.49c.76,0,1.53,0,2.28.07,1-2.82,2.13-5.77,3.41-8.63a48.7,48.7,0,0,0-5.69-.34,48,48,0,0,0-24.48,6.62,47.82,47.82,0,0,0-24.21-6.62,47.27,47.27,0,0,0-37.17,17.86c-.9,0-1.8-.07-2.7-.07a53.88,53.88,0,0,0-53.32,46.29A52.4,52.4,0,0,0,89.94,205c11.1,12.79,31.39,20.74,54.16,20.74q3.26,0,6.5-.23a55,55,0,0,0,92.83,13.28c3.08,3.24,13.27,15.51,9.9,32.58,0,0,15.51-11.43,9.25-40.41A48.42,48.42,0,0,0,296,165.46,36.62,36.62,0,0,0,302.59,144.36Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "LogoSvg",
};
</script>

<style></style>
